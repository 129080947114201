import React, { useState } from 'react';
import './planilhas.css'
import PlanilhaCoco from './PlanilhaCoco';
import PlanilhaBeneficiado from './PlanilhaBeneficiado';
import PlanilhaMaquina from './PlanilhaMaquina';

function Planilhas() {
  // Estado para controlar a guia ativa
  const [activeTab, setActiveTab] = useState('Café Coco');
  
  return (
    <div className='App'>
      <div className='desc'>
        <h2>Planilhas de Entrada do Barracão:</h2>
      </div>
      <div className='tabs'>
        <button className={activeTab === 'Cafe Coco' ? 'active' : ''} onClick={() => setActiveTab('Café Coco')} >Café Coco (C)</button> {/*O botão estará visualmente ativo qdo a guia Café Coco estiver selecionada*/}
        <button className={activeTab === 'Cafe Beneficiado' ? 'active' : ''} onClick={ () => setActiveTab('Café Beneficiado')}>Café Beneficiado (E)</button> {/*setActiveTab é usada para controlar qual guia está ativa*/}
        <button className={activeTab === 'Cafe Máquina' ? 'active' : ''} onClick={() => setActiveTab('Café Máquina')}>Café Máquina (M)</button>
      </div>
      <div className='tab-content'>
        {activeTab === 'Café Coco' && <PlanilhaCoco/>}  {/*'===' Verifica valor e tipo*/}
        {activeTab === 'Café Beneficiado' && <PlanilhaBeneficiado/>}
        {activeTab === 'Café Máquina' && <PlanilhaMaquina/>}
      </div>
    </div>
  );
}
export default Planilhas;