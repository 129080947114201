import axios from '../../Configuracoes/axiosConfig';


export const marcarLoteComoUtilizado = async (url, lote) => {
  if (lote){
    console.log("O lote selecionado eh: ", lote);

    try {
        //Faz a requisição ao endpoint com os dados recebidos
        console.log("Está antes do axios em marcarLoteComoUtilizado!");
        await axios.put(url, {
           lote: lote,
           ativo: 0
        });
        console.log("Está fora do axios em marcarLoteComoUtilizado!");
        console.log('Lote marcado como já utilizado pelo armazem!');
        return { success: true };
    } catch (error) {
        console.error('Erro ao marcar lote como já utilizado', error);
        return { success: false, error: error };
    }
  } else {
    console.log ('Nenhum lote selecionado.');
    return { success: false, error: 'Lote não encontrado!' }
  }
};

// Função que unifica a lógica dos componentes
export const handleMarcarModalComoUtilizado = async (selectedItem, buscarDados, fecharmodalUtilizado, url) => {
  if (selectedItem && selectedItem.ativo == true) {
  const lote = selectedItem.lote;

  const response = await marcarLoteComoUtilizado(url, lote);

  if (response.success) {
    buscarDados();
    buscarDados();
    fecharmodalUtilizado();
    alert('Lote marcado como já utilizado pelo armazém!');
  } else {
      console.error('Erro ao marcar lote como já utilizado: ', response.error);
  }
 } else {
    console.log('Não há item selecionado ou "ativo" está diferente de true');
    fecharmodalUtilizado();
 }
}