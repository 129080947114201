import './FormularioCoco.css'
import React, { useState, useEffect } from 'react';
import { formatarData } from '../../Utilitarios/utilitario_de_data';  
import { dataAmericana } from '../../Utilitarios/formatarDataFormatoAmericano';
import { Link } from 'react-router-dom';
import axios from '../../../Configuracoes/axiosConfig';
import { TextField } from '@mui/material';
import { marcarLoteComoUtilizado } from '../../Funçoes/utilizarLote';

const FormularioCoco = () => {  

  const [sugestoes, setSugestoes] = useState([]); // Estado para lidar com sugestões de nomes de produtores
  const [inputSearch, setInputSearch] = useState(''); // Estado para lidar com a pesquisa do nome filtrado
  const [selectedItem, setSelectedItem] = useState(null); //Estado para rastrear o item selecionado na tabela
  const [loteParaEditar, setLoteParaEditar] = useState(null);
  const [confirmarEdicao, setConfirmarEdicao] = useState(false);
  const [lotesConsultados, setLotesConsultados] = useState([]); //'Estado' para armazenar os lotes filtrados com base no produtor consultado
  const [consultarPressionado, setConsultarPressionado] = useState(false); //Variável de valor booleano que verifica se o botão "Consultar" foi pressionado ou não. É inicializada como 'false'.
  const [exibirReferencia, setExibirReferencia] = useState(false); //Estado para controlar a exibição do campo "Referência"com base na opção selecionada no select. É inicializado como 'false'.
  const [dataSelecionada, setDataSelecionada] = useState('');
  const [mostrarTelaConfirmacao, setmostrarTelaConfirmacao] = useState(false);
  const [itemSelecionado, setItemSelecionado] = useState(null);
  const [showModalUtilizado, setShowModalUtilizado] = useState(false);
  const [showModalDisponivel, setShowModalDisponivel] = useState(false);

  // Estado para controlar os dados inseridos
  const [dados, setDados] = useState([]); //Estado para controlar os 'dados'inseridos
  const [novoItem, setNovoItem] = useState({ //Estado para inserir um novo item
    lote: '',
    produtor: '',
    apelido: '',
    meieiro: '',
    data: '',
    sacos: '',
    quilos: '',
    barracao: '',
    status:'',
    referencia:'',
    subproduto: '',
    observacoes: '',
    ativo: ''
  });

  useEffect(() => {
    if (mostrarTelaConfirmacao) {
      abrirModalConfirmacao(); // Chama a função para abrir o modal *******************************
    }
  }, [mostrarTelaConfirmacao]);

  //Função para buscar lotes de Café em Côco
  const buscarDados = async () => {//assync = função assíncrona, retorna uma 'promisse'.
    try{ //O await faz com que a execução do código dentro da função seja pausada até que a promessa retornada por axios.get(...) seja resolvida ou rejeitada.
      const response = await axios.get('http://193.203.183.215:8080/cafecoco');
      console.log("A resposta de buscarDados em FormularioCoco foi", response);
      // if (!response.ok) {
      //   throw new Error('Erro ao buscar um Café em Coco! Código de resposta: '+ response.status);
      // }
      
      if (response && response.status >= 200 && response.status < 300) {
        const dadosRecebidos = /*await*/ response.data /*response.json()*/;
        setDados(dadosRecebidos); // Definindo o estado 'dados' diretamente com a resposta da API
        console.log('Dados recebidos (Café Côco): ',dadosRecebidos); //Verificando o retorno da API no Console ***********************************        
        return dadosRecebidos; //Retorna os dados para serem usados posteriormente
      } else {
        console.error('Erro ao buscar um Café em Coco! Código de resposta: ', response ? response.data : 'Indefinido');
        return [];
      }

    }catch(error){
      console.error('Erro ao buscar um Café em Coco!', error);
      return []; //Retorna um array vazio em caso de erro
    }
  };

  // Função para adicionar um novo item à tabela
  const handleInserirItem = async () => {
    
    // Verifica se os campos obrigatórios (Produtor, sacos, quilos e data) estão vazios
    if( 
        novoItem.produtor === '' ||
        novoItem.sacos === '' ||
        novoItem.quilos === '' ||
        novoItem.data === ''
      ) {
        alert('Preencha os campos produtor, sacos, quilos e data para cadastrar!');
        return;
      }
      
    if(novoItem.status === ""){
      alert("Selecione um status para cadastrar um novo lote!");
    } else {
      try {
          // Verifica primeiro se o produtor está cadastrado
          const response = await axios.get(`http://193.203.183.215:8080/produtor/nome/${novoItem.produtor}`);

          if(response.data){
            // Insere o novo item de Café em Côco usando axios.post
            await axios.post('http://193.203.183.215:8080/cafecoco', novoItem);

            //atualizar a lista de dados após a inserção
            buscarDados();
            
            //limpar campos após a inserção
            setNovoItem({
              produtor: '',
              apelido: '',
              meieiro: '',
              data: '',
              sacos: '',
              quilos: '',
              barracao: '',
              status:'',
              subproduto: '',
              observacoes: '',
              referencia: '' //limpar também o campo de referência
            });
            setInputSearch('');
          } else {
            //Produtor não encontrado, exibe mensagem de aviso!
            alert('O produtor que você está tentando inserir não está cadastrado. Cadastre o produtor antes de inserir um novo lote!');
          }
      } catch(error) {
          console.error('Erro ao cadastrar novo lote de Café Côco:', error);
      }
    }
  };

  // Função para lidar com a mudança no campo "Produtor"
  const handleProdutorChange = (e) => { // Chama `handleFilter` e `setNovoItem` ao mesmo tempo
    const novoValor = e.target.value;
    setNovoItem({ ...novoItem, produtor: novoValor });
    handleFilter(e); // Chame a função handleFilter aqui para atualizar sugestões
  };  
  
  const getStatusClass = (status) => { //Obtém o status para inserir o css apropriado na linha da tabela
    const statusLowerCase = status.toLowerCase(); //Converte o status para letras minúsculas
    
    switch (statusLowerCase){
      case 'já adquirido':
        return 'status-adquirido';
      case 'já beneficiado':
        return 'status-beneficiado';
      case 'vai beneficiar no barracao':
        return 'status-barracao';
      default:
        return '';
    }
  }

  useEffect(() => { //dispara uma função toda vez que uma variável muda. O Hook useEffect é usado para realizar a busca inicial dos dados do banco de dados assim que o componente é montado (componentDidMount). Ele chama a função buscarDados quando o componente é montado.
    buscarDados(); //Buscar dados do backend ao carregar o componente
  }, [/*lotesInseridos*/]); //Atualizar dados quando novos lotes forem inseridos

  useEffect(() => {
    buscarSugestoes();
  }, [inputSearch]);

  const buscarSugestoes = async () => {
    if(inputSearch.length >= 3){
      try{
        const response = await axios.get(`http://193.203.183.215:8080/produtor/filter?nome=${inputSearch}`);
        setSugestoes(response.data); //Salva o resultado das sugestões retornados após a busca de nomes de produtores filtrados
      } catch (error) {
        console.error('Erro ao buscar sugestões de produtores', error);
        setSugestoes([]);
      }
    } else {
      setSugestoes([]);
    }
  }

  const handleSelecionarSugestao = (sugestao) => { // Função que seleciona o produtor sugerido ao clicar nele
    console.log("Produtor selecionado:", sugestao.nome);
    setInputSearch(sugestao.nome); // Seleciona o nome da sugestão escolhido
    setSugestoes([]); // Limpa as sugestões após selecionar uma sugestão
    setNovoItem({ ...novoItem, produtor: sugestao.nome });
  }; 

  const handleFilter = (event) => { //atualiza o valor do estado inputSearch com o valor do input que foi alterado. O estado inputSearch
    setInputSearch(event.target.value); //é usado para armazenar o valor do input "Produtor".
  }

  // Função para abrir o modal de confirmação de alteração
  const abrirModalConfirmacao = () => {
    console.log('abrirModalConfirmacao() foi chamada!');
    //Verifica se os campos obrigatórios (produtor, sacos, quilos e data) não estão vazios
    if (  novoItem.produtor === '' ||
          novoItem.sacos === '' ||
          novoItem.quilos === '' ||
          novoItem.data === ''                
    ){
        alert('Preencha os campos produtor, sacos, quilos e data para Editar!');
        console.log('selectedItem não foi selecionado! :(');
    }       
    else{ //Se os campos obrigatórios ñ estiverem vazios...
        if (selectedItem) { //Se alguma linha da tabela foi selecionada
          console.log('selectedItem foi selecionado!');
          if (!mostrarTelaConfirmacao) {
            setmostrarTelaConfirmacao(true);
          }
          setLoteParaEditar(selectedItem);
          //setmostrarTelaConfirmacao(true); //Exibe a tela de confirmacao
          setConfirmarEdicao(true);
          console.log('O conteúdo de mostrarTelaConfirmacao é: ' + mostrarTelaConfirmacao);
        }
        else{
          console.log('selectedItem não foi selecionado! :(');
        }
    }
  }

  //Função de manipulação de click na linha da tabela para carregar dados nos inputs ao clicar na linha
  const clickLinhaTabela = (item) => {
    const dataFormatada = dataAmericana(item.data);
        
      //Atualiza o estado dataSelecionada com a data formatada em formato americano
      setDataSelecionada(dataFormatada);

      setSelectedItem(item); //Rastreia o item (linha) selecionado na tabela

      //Preenche os campos de entrada com os dados da linha clicada
      setNovoItem({
        lote: item.lote,
        produtor: item.produtor,
        meieiro: item.meieiro,
        data: dataFormatada, // Define a data no formato "AAAA-MM-DD"
        sacos: item.sacos,
        quilos: item.quilos,
        barracao: item.barracao,
        referencia: item.referencia,
        subproduto: item.subproduto,
        observacoes: item.observacoes,
        status: item.status // Atualiza o estado "status" com o status do lote selecionado
      });
      
      setInputSearch(item.produtor); //Atualiza o valor do input search
      setLoteParaEditar(item); // Define o item selecionado no estado loteParaEditar
      //abrirModalConfirmacao(item);
  }
  
  // Função para fechar o modal de confirmação de alteração
  const fecharTelaConfirmacao = () => {
    setLoteParaEditar(null);
    setConfirmarEdicao(false);
    setmostrarTelaConfirmacao(false);
  };

  const consultarLotes = () => {
    const lotesFiltrados = dados.filter(item => item.produtor.toLowerCase().includes(novoItem.produtor.toLowerCase()));
    setLotesConsultados(lotesFiltrados);
    setConsultarPressionado(true);
  }

  //Função para confirmar a edição
  const confirmarEdicaoLote = async () => {
    
    //Certificando de que loteParaEditar não é nulo
    if (loteParaEditar) { //Se o loteParaEditar não é nulo
        
      console.log(`loteParaEditar: + ${JSON.stringify(loteParaEditar)}`);
      if (novoItem.status === "") {
        alert('Selecione um status antes de editar!');
      } else{
        // Agora pode enviá-los para o servidor
        try{        
              // Acessa os valores dos campos do formulário a partir do estado novoItem 
              const { lote, produtor, meieiro, data, sacos, quilos, barracao, status, subproduto, observacoes } = novoItem;
              console.log('novoLote: '+ JSON.stringify(novoItem));

              // Formata a data para o formato americano antes de enviar para o backend
              const dataFormatoAmericano = dataAmericana(novoItem.data);

              const requestBody = {
                lote,
                produtor,
                data,
                meieiro,
                sacos,
                quilos,
                barracao,
                status,
                subproduto,
                observacoes
              }

          await axios.put('http://193.203.183.215:8080/cafecoco', requestBody);

          const dadosAtualizados = await buscarDados();
          setDados(dadosAtualizados);

          //Feche o modal de confirmação
          fecharTelaConfirmacao();

          setNovoItem({
            lote: '',
            produtor: '',
            data: '',
            meieiro: '',
            sacos: '',
            quilos: '',
            barracao: '',
            status: '',
            subproduto: '',
            observacoes: ''
          });
          setInputSearch('');

        } catch(error){
          console.error('Erro ao editar o lote: '+ loteParaEditar, error);
        }
      }
    } else {
      alert('loteParaEditar na função "confirmarEdicaoLote" qdo clica no botão Editar está vazio');
    }
  }

  const abrirModalUtilizado = (item) => {
    console.log("O valor de 'ativo' é: ", item.ativo);
    setShowModalUtilizado(true);
    setItemSelecionado(item);
  }

  const fecharModalUtilizado = () => {
    setShowModalUtilizado(false);
  }

  const abrirModalDisponivel = (item) => {
    console.log("O valor de 'ativo' é: ", item.ativo);
    setShowModalDisponivel(true);
    setItemSelecionado(item);
  }

  const fecharModalDisponivel = () => {
    setShowModalDisponivel(false);
  }

  const handleMarcarModalComoUtilizado = async () => {
    if(selectedItem && selectedItem.ativo == true){
      const lote =selectedItem.lote; //Obtém o número do lote
      const url = 'http://193.203.183.215:8080/cafecoco';
      console.log("O lote selecionado eh: ", lote);

      const result = await marcarLoteComoUtilizado(url, lote);

        if (result.success){
            buscarDados();
            buscarDados();
            fecharModalUtilizado();
            alert('Lote marcado como já utilizado pelo armazém!');
          } else {
        console.error('Erro ao marcar lote como já utilizado', result.error);
      }
    } else {
      console.log('Não há item selecionado ou ativo está diferente de "true" ');
      fecharModalUtilizado();
    }
  }

  return (
    <div className='div'>
      <Link to="/paginainicial">Voltar</Link> {/* Usando o componente Link para criar um link para a página inicial */}

      <div className='div_l'>
        <h2>Café em Côco</h2>
      </div>
        {/* Inputs para cada atributo */}
        <div className='input-row'>               
          <div className={`search-bar ${inputSearch.length > 0 ? 'active' : ''}`}> {/*Realiza uma operação de renderização condicional de classes CSS com base no valor da variável inputSearch.*/}
            <input
              value= {inputSearch}
              onChange= {handleProdutorChange} //{(e) => setNovoItem({ ...novoItem, produtor: e.target.value })} //pega o valor do produto (evento."alvo".valor)
              type="text"
              placeholder="Produtor"
              name='produtor'
              className='prod'
            />
            {/* <TextField required id="text_prod" className='text_prod' label='Produtor' defaultValue="text" variant='outlined' value={inputSearch}
              onChange={handleProdutorChange} style={{marginRight: '10px'}}/> */}

            {sugestoes.length > 0 && (
              <ul>
                {sugestoes.map((sugestao) => (
                  <li key={sugestao.id} onClick={() => handleSelecionarSugestao(sugestao)}>
                   {sugestao.nome}
                  </li>
                ))}
              </ul>
            )}
          </div>
            <input
              type="text"
              placeholder="Meieiro"
              value={novoItem.meieiro}
              onChange={(e) => setNovoItem({ ...novoItem, meieiro: e.target.value })}
              className='mei'
            />
          <br></br>
            <input
              type="date"
              placeholder="Data"
              value={novoItem.data}
              onChange={(e) => setNovoItem({ ...novoItem, data: e.target.value })}
              className='dat'
            />
            <input
              type="text"
              placeholder="Sacos"
              value={novoItem.sacos}
              onChange={(e) => setNovoItem({ ...novoItem, sacos: e.target.value })}
              className='sac'
            />
            <input
              type="text"
              placeholder="Quilos"
              value={novoItem.quilos}
              onChange={(e) => setNovoItem({ ...novoItem, quilos: e.target.value })}
              className='qui'
            />
            <input
            type="text"
            placeholder="Barracão"
            value={novoItem.barracao}
            onChange={(e) => setNovoItem({ ...novoItem, barracao: e.target.value })}
            className='bar'
            />
            <select 
              value={novoItem.status}
              onChange={(e) => setNovoItem({ ...novoItem, status: e.target.value})} //Caso seja clicado, acionará o evento para adicionar o novo item selecionado
              className='st'>
              <option value="">Selecione o status:</option> {/* Opção padrão */}
              <option value="Vai beneficiar no barracao">Vai beneficiar no barracão</option>
              <option value="Já adquirido">Já adquirido</option>
              <option value="Já beneficiado">Já beneficiado</option>
            </select>
            { novoItem.status === "Já beneficiado" && (
              <input
              type="text"
              placeholder="Referência"
              /*value={exibirReferencia}*/
              onChange={(e) => setExibirReferencia(e.target.value)}
              className='ref'/>
            )} {/* Se o select estiver selecionado como "Já beneficiado" 'e' o botão Inserir for pressionado */}

            <input
              type="text"
              placeholder="Mooca, Escolha, Painha..."
              value={novoItem.subproduto}
              onChange={(e) => setNovoItem({ ...novoItem, subproduto: e.target.value })}
              className='sub'
            />
          </div>
          <div className='input-row'>
            <input
              type="text"
              placeholder="Observações"
              value={novoItem.observacoes}
              onChange={(e) => setNovoItem({ ...novoItem, observacoes: e.target.value })}
              className='obs'
            />
          </div>
          <div className='div_b'>
            <button className='but_m_b' onClick={handleInserirItem}>Inserir</button>
            <button className='but2_m_b' onClick={abrirModalConfirmacao}>Editar</button>
            <button className='but3_m_b' onClick={consultarLotes}>Consultar</button>
          </div>
        
        <div className='table-container-c'>
          {/* Tabela para exibir os últimos registros */}
          <table className='table-c'>
            <thead> {/*Cabeçalho*/}
              <tr>
                <th style={{backgroundColor: '#00FF00', color: 'black'}}>Lote</th>
                <th style={{backgroundColor: '#00FF00', color: 'black'}}>Data</th>
                <th style={{backgroundColor: '#00FF00', color: 'black'}}>Sacos</th>
                <th style={{backgroundColor: '#00FF00', color: 'black'}}>Quilos</th>
                <th style={{backgroundColor: '#00FF00', color: 'black'}}>Produtor</th>
                <th style={{backgroundColor: '#00FF00', color: 'black'}}>Meieiro</th>
                <th style={{backgroundColor: '#00FF00', color: 'black'}}>Status</th>
                <th style={{backgroundColor: '#00FF00', color: 'black'}}>Referência</th>
                <th style={{backgroundColor: '#00FF00', color: 'black'}}>Barracao</th>
                <th style={{backgroundColor: '#00FF00', color: 'black'}}>Observacoes</th>
              </tr>
            </thead>
            <tbody>
              {consultarPressionado
                ? lotesConsultados.reverse().map((item, index) => (
                // ? lotesConsultados.slice().sort((a, b) => b.lote - a.lote).map((item, index) => (
                <tr key={index} onClick={() => clickLinhaTabela(item)} className={selectedItem === item ? 'selected-row' : ''}>
                    <td>{'C-0'+ item.lote}</td>
                    <td>{formatarData(item.data)}</td>
                    <td style={{textAlign: 'center'}}>{item.sacos}</td>
                    <td style={{textAlign: 'center'}}>{item.quilos}</td>
                    <td style={{textAlign: 'center'}}>{item.produtor}</td>
                    <td>{item.meieiro}</td>
                    <td>{item.status}</td>
                    <td>{item.referencia}</td>
                    <td style={{textAlign: 'center'}}>{item.barracao}</td>
                    <td>{item.observacoes}</td>
                </tr>
                ))
                : dados.reverse().map((item, index) => (
                //: dados.slice().sort((a, b) => b.lote - a.lote).map((item, index) => (
                <tr key={index}
                onClick={() => clickLinhaTabela(item)} className={selectedItem === item ? 'selected-row' : ''}>
                  <td>{'C-0'+ item.lote}</td>
                  <td>{formatarData(item.data)}</td>
                  <td style={{textAlign: 'center'}}>{item.sacos}</td>
                  <td style={{textAlign: 'center'}}>{item.quilos}</td>
                  <td style={{textAlign: 'center'}}>{item.produtor}</td>
                  <td>{item.meieiro}</td>
                  <td>{item.status}</td>
                  <td>{item.referencia}</td>
                  <td style={{textAlign: 'center'}}>{item.barracao}</td>
                  <td>{item.observacoes}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
        {/* Modal de confirmação */}
        { mostrarTelaConfirmacao && (  // Se confirmarEdicao for 'true' e modalConfirmacao - aí renderiza
          <div className="modal-confirmacao">
            <div className="modal-content">
              <p>Tem certeza que deseja alterar os dados desse lote?</p>
              <button onClick={confirmarEdicaoLote}>Sim</button>
              <button onClick={fecharTelaConfirmacao}>Não</button>
            </div>
          </div>
        )}
    </div>
  );
};

export default FormularioCoco;