import React, { useState } from 'react';
import './Barracao.css'
import FormularioCoco from './FormularioCoco';
import FormularioBeneficiado from './FormularioBeneficiado';
import FormularioMaquina from './FormularioMaquina';
import FormularioCafeVerde from './FormularioCafeVerde';

function Barracao() {
  // Estado para controlar a guia ativa
  const [activeTab, setActiveTab] = useState('Café Coco');
  
  return (
    <div className='App'>
      <div className='desc_b'>
        <h2>Formulário de Entrada do Armazém:</h2>
      </div>
      <div className='tabs'>
        <button className={activeTab === 'Café Coco' ? 'active' : ''} onClick={() => setActiveTab('Café Coco')} >Café Coco (C)</button> {/*O botão estará visualmente ativo qdo a guia Café Coco estiver selecionada*/}
        <button className={activeTab === 'Café Beneficiado' ? 'active' : ''} onClick={ () => setActiveTab('Café Beneficiado')}>Café Beneficiado (E)</button> {/*setActiveTab é usada para controlar qual guia está ativa*/}
        <button className={activeTab === 'Café Máquina' ? 'active' : ''} onClick={() => setActiveTab('Café Máquina')}>Café Máquina (M)</button>
        {/* <button className={activeTab === 'Café Verde' ? 'active' : ''} onClick={() => setActiveTab('Café Verde')}>Café Verde</button>
        <button className={activeTab === 'Serviços Internos' ? 'active' : ''} onClick={() => setActiveTab('Serviços Internos')}>Serviços Internos</button> */}
      </div>
      <div className='tab-content'>
        {activeTab === 'Café Coco' && <FormularioCoco/>}  {/*'===' Verifica valor e tipo*/}
        {activeTab === 'Café Beneficiado' && <FormularioBeneficiado/>}
        {activeTab === 'Café Máquina' && <FormularioMaquina/>}
        {/* {activeTab === 'Café Verde' && <FormularioCafeVerde/>} */}
      </div>
    </div>
  );
}
export default Barracao;