import axios from '../../Configuracoes/axiosConfig';


export const marcarLoteComoDisponivel = async(url, lote) => {
    if (lote){
      console.log("O lote selecionado eh: ", lote);

     try {
        // Faz a requisiçao ao enpoint com os dados recebidos
        console.log("Está antes do axios em marcarLoteComoDisponivel!");
        await axios.put(url, {
            lote: lote,
            ativo: 1
        });
        console.log("Está fora do axios em marcarLoteComoDisponivel!");
        console.log('Lote marcado como disponível com sucesso!');

        //retorna { success: true } em caso de sucesso
        return { success: true };
     } catch (error) {
        console.error('Erro ao desmarcar lote como já utilizado: ', error);
        //Retorna { success: false } em caso de erro
        return { success: false, error: error };
     }
    } else {
        console.log('Nenhum lote selecionado.');
        return { success: false, error: 'Lote não encontrado.'};
    }
}

export const handleMarcarModalComoDisponivel = async (selectedItem, buscarDados, fecharModalDisponivel, url) => {
    if(selectedItem && selectedItem.ativo == false){
      const grupo = selectedItem.lote;
      console.log("O lote selecionado eh: ", grupo);
      
      const agrupamento = await marcarLoteComoDisponivel(url, grupo);

      if (agrupamento.success){
         buscarDados();
         buscarDados();
         fecharModalDisponivel();
         alert('Lote marcado como disponível para ser utilizado!');
      } else {
        console.error('Erro ao marcar lote como disponível!');
      }
    } else {
      console.log('Não há item selecionado ou ativo está diferente de "false" ');
      fecharModalDisponivel();
    }
  }