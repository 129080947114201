import React, { useEffect, useState} from "react";
import './login.css'
import './PaginaInicial'
import { useHistory } from 'react-router-dom';
import cadeado from './img/cadeado-aberto.avif';
import axios from '../src/Configuracoes/axiosConfig';
import logo_lidere from './img/Logo Lidere Software 2.png';
import token from '../src/Configuracoes/axiosConfig';


const Login = () => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); //Estado para armazenar mensagens de erro
  const history = useHistory();

  useEffect(() => {
    document.title = 'Minas Café';
  });

  const handleLogin = async () => {
    if (!user || !password) {
      setErrorMessage("Preencha corretamente o usuário e a senha para realizar o login!");
      return;
    }

    try{        
        const response = await axios.post('http://193.203.183.215:8080/auth/login', {
          login: user,
          senha: password
        }, {
             headers: {
            'Content-Type': 'application/json'//,
          //  Authorization: `Bearer ${token}`
           }
        });
        // const response = await fetch('http://193.203.183.215:8080/auth/login', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json'
        //   },
        //   body: JSON.stringify({
        //     login: user,
        //     senha: password
        //   })
        // });
        
        if (response.status === 200) {
          // Se a autenticação for bem sucedida, armazenar o token JWT no localStorage
          localStorage.setItem('token', response.data.token);
          console.log('Login bem sucedido');
          
           // Redireciona para a página inicial
           history.push('/paginainicial');
        } else {
          const errorMessage = response.data.message;// await response.text(); - supondo que o erro retorne o corpo da resposta
          throw new Error(errorMessage);
        }
          
    } catch (error){
      //Trate qualquer erro aqui
      console.error('Erro ao fazer login: ', error);
      if(error.response){
        setErrorMessage("Erro ao fazer login: "+ error.response.data.message);
      } else {
        setErrorMessage("Usuário ou senha incorretos, tente novamente!");
      }
    }
  }

  const handleuserClick = () => {
    setErrorMessage("");
  }

  const handlePasswordClick = () => {
    setErrorMessage("");
  }

  return (
    <div className="container">
      <div className="titulo">  
        <label>Efetue login para acessar o sistema:</label>
      </div>
      <div className="password-input">
         <img src={cadeado} alt="Cadeado" />
      </div>
      <div className="form-group">
        <label>Nome:</label>
        <input type="text" /*placeholder="Usuário"*/ name="usuario" value={user} onChange={(e) => setUser(e.target.value)} onClick={handleuserClick}/>
      </div>
      <div className="form-group">
        <label className="lab">Senha:</label>          
          <input type="password" /*placeholder="Senha"*/ name="senha" value={password} onChange={(e) => setPassword(e.target.value)} onClick={handlePasswordClick}/>
      </div>
       {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Exibe a mensagem de erro, se houver */}
        <button onClick={handleLogin}>Logar</button>

        <div className="div_info">
          <div classname="div_image">
            <img src={logo_lidere} alt="Logo Lidere Software" className="minha-imagem"/>
          </div>
          <div classname="div_tit">
            <h6 classname="vers">Versão 1.0.2</h6>
          </div>
        </div>
    </div>
  );
};

export default Login;