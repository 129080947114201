import React from 'react';
import './FichaProdutor.css'
import { Link, useHistory } from 'react-router-dom' //useHistory para navegar entre páginas

function fichaprodutor(){


    return(
     <div className='container-prod'>
        <div className='tex'>
          <h1>Você está na página de Ficha de produtor!</h1>
        </div>
        <div className='div_button'>
          <Link to="/escritorio" className='linkto'>
            <button className='button_fic'>Voltar</button>
          </Link>
        </div>
     </div>
    );
}

export default fichaprodutor;