import React from 'react';

function PaginaNaoEncontrada() {
    return (
     <div className='pag' border>
        <h1>Erro 404 - Página não encontrada</h1>
        <p>A página que você está procurando não foi encontrada.</p>
     </div>
    );
}

export default PaginaNaoEncontrada;